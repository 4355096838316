<template>
  <div>
    <zona-list-add-new
      :is-add-new-zona-sidebar-active.sync="isAddNewZonaSidebarActive"
      :edit-item="editItem"
      :zonas="zonasList"
      @closeDialog="closeDialog"
      @rowClicked="rowClicked"
      @addZona="addZona"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      :aria-hidden="loading ? 'true' : null"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="createZona()"
              >
                <span class="text-nowrap">Crear Zona</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refZonasListTable"
        class="position-relative"
        :items="fetchZonas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchZonas"
        @row-clicked="rowClicked($event.item)"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="
              isAddNewZonaSidebarActive = true;
              editItem = data.item;
            "
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Zona -->
        <template #cell(nombre)="data">
          {{ data.item.parent ? data.item.nombre : '' }}
        </template>

        <!-- Column: Núcleo -->
        <template #cell(parent.nombre)="data">
          {{ data.item.parent ? data.item.parent.nombre : data.item.nombre }}
        </template>

        <!-- Column: Es Mantenimiento -->
        <template #cell(isMantenimiento)="data">
          <b-badge
            pill
            :variant="`light-${statusColor(data.item.isMantenimiento)}`"
          >
            <feather-icon
              v-if="data.item.isMantenimiento"
              icon="CheckCircleIcon"
            />
            <feather-icon
              v-else
              icon="XCircleIcon"
            />
          </b-badge>
        </template>
        <!-- Column: Id -->
        <template #cell(parent)="data">
          <p v-if="data.item.parent">
            {{ data.item.parent.nombre }}
          </p>
          <p v-else>
            -
          </p>
        </template>
        <template #cell(actions)="data">
          <b-row align-h="center">
            <b-button
              :id="`edit-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="success"
              @click="
                isAddNewZonaSidebarActive = true;
                selectedItem = data.item;
                editZona(data.item);
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              :id="`delete-${data.item.id}`"
              class="mr-1 btn-icon"
              variant="danger"
              @click="
                deleteModal = true;
                selectedItem = data.item;
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-row>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalZonas"
              :per-page="perPage"
              aria-controls="zonasTable"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template v-slot:prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template v-slot:next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el zona."
      title="¿Está seguro de que desea eliminar el zona?"
      @action="deleteZona(selectedItem.id)"
      @close="deleteModal = false"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import useZonasList from '@/views/work/zonas/useZonasList'
import vSelect from 'vue-select'
import ZonaListAddNew from '@/views/work/zonas/ZonasForm.vue'
import store from '@/store'
import zonaStoreModule from '@/views/work/zonas/zonaStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    ZonaListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
    vSelect,
    ConfirmationModal,
  },

  data() {
    return {
      deleteModal: false,
      isAddNewZonaSidebarActive: false,
      editItem: null,
      selectedItem: {},
    }
  },
  computed: {
    loading() {
      return this.$store.state['app-zona'].loading
    },
  },

  methods: {
    statusColor(status) {
      return status === 'invited' ? 'warning'
        : status === 'disabled' ? 'dark'
          : status == false ? 'danger'
            : 'success'
    },
    rowClicked(item) {
      this.selectedRow = item
    },
    async createZona() {
      this.isAddNewZonaSidebarActive = true
      await this.refetchData
    },
    addZona(obj) {
      this.zonaSelect(obj)
    },
    closeDialog() {
      this.isAddNewZonaSidebarActive = false
      this.editItem = null
    },
    deleteZona(id) {
      this.deleteModal = true
      this.$store.dispatch('app-zona/deleteZona', id).then(() => {
        this.deleteModal = false
        this.refetchData()
        this.removeZoneFromSelect(id)
      })
    },
    editZona(zona) {
      this.isAddNewZonaSidebarActive = true
      this.editItem = zona
    },
  },
  setup() {
    const ZONAS_APP_STORE_MODULE_NAME = 'app-zona'

    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME)
    })

    const isAddNewZonaSidebarActive = ref(false)
    const editItem = ref(null)
    const deleteItem = ref(null)
    const listOfZonas = []
    const refreshItem = ref(null)
    const zonasList = ref([])

    const {
      fetchZonas,
      tableColumns,
      perPage,
      currentPage,
      totalZonas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refZonasListTable,

      refetchData,
      listZonas,

      // Extra Filters
      statusFilter,
    } = useZonasList()

    listZonas().then(data => {
      zonasList.value = data.filter(zona => !zona.parent)
    })

    const zonaSelect = zona => {
      if (!zona.parent && zonasList.id !== zona.id) {
        zonasList.value.push(zona)
      }
    }

    const removeZoneFromSelect = value => {
      const index = zonasList.value.findIndex(zona => zona.id === value)
      zonasList.value.splice(index, 1)
    }

    return {
      removeZoneFromSelect,
      zonasList,
      zonaSelect,
      refreshItem,
      editItem,
      isAddNewZonaSidebarActive,
      fetchZonas,
      tableColumns,
      perPage,
      currentPage,
      totalZonas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refZonasListTable,
      listOfZonas,
      refetchData,
      listZonas,
      deleteItem,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>
